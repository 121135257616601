import Form from "react-bootstrap/Form";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import { toFloatBgn} from "../../../../utils/functions.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import React, {useEffect} from "react";
import {fetchArticles, resetStateData} from "../../../../slices/articleListSlice.js";
import {useDispatch} from "react-redux";
import FormInput from "../../../../components/Elements/FormInput.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Table} from "react-bootstrap";

const EditOrderLineComponent = (
    {
        onAttributeChange,
        editOrderLine,
        onArticleChange,
        errors,
        onPriceRelatedChange,
        articleTypeLabel
    }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetStateData());
        return () => {
            dispatch(resetStateData());
        };
    }, [editOrderLine.orderLineType])

    return (
        <Form.Group className="mb-3" controlId="formHorizontalEmail">
            <SearchAutoCompleteComponent
                onItemChange={onArticleChange}
                item={editOrderLine.article ? editOrderLine.article : null}
                name={'article'}
                label={'Артикул'}
                fetchItems={fetchArticles}
                reducer={'articleList'}
                errors={errors}
                searchFilters={{'article_type_label': articleTypeLabel}}
            />

            {editOrderLine.article && (
                <>
                    <Row className={'mt-2'}>
                        <Col md={12}>
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className={'col-5'}>Количество</th>
                                    <th className={'col-1'}>Мярка</th>
                                    <th className={'col-2'}>Цена</th>
                                    <th className={'col-2'}>Отстъпка</th>
                                    <th className={'col-2'}>Цена общо</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'col-5'}>
                                        <Row>
                                            <Col sm={3}>
                                                <FormNumericInput
                                                    name={'quantity'}
                                                    value={editOrderLine.quantity}
                                                    onChange={onPriceRelatedChange}
                                                    errors={errors}
                                                />
                                            </Col>
                                        </Row>
                                    </td>
                                    <td className={'col-1'}>{editOrderLine.article.quantityType.name}</td>

                                    <td className={'col-2'}>
                                        <FormNumericInput
                                            name={'price'}
                                            value={editOrderLine.price}
                                            onChange={onPriceRelatedChange}
                                            errors={errors}
                                        />
                                    </td>
                                    <td className={'col-2'}>
                                        <FormNumericInput
                                            name={'discount'}
                                            value={editOrderLine.discount}
                                            onChange={onPriceRelatedChange}
                                            errors={errors}
                                        />
                                    </td>
                                    <td className={'col-2'}>{toFloatBgn(editOrderLine.totalPrice)}</td>

                                </tr>


                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <FormInput
                        as={'textarea'}
                        name={'additionalInformation'}
                        value={editOrderLine.additionalInformation}
                        label={'Допълнителна информация'}
                        errors={errors}
                        onChange={onAttributeChange}
                    />
                </>
            )}
        </Form.Group>
    );
};

export default EditOrderLineComponent;