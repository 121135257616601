import {Routes, Route} from "react-router-dom";
import CarModelListPage from "../pages/CarModels/List/CarModelListPage.js";
import CarModelViewPage from "../pages/CarModels/Single/CarModelViewPage.js";
import {hasPermission} from "../utils/permissions.js";
import CarModelEditPage from "../pages/CarModels/Single/CarModelEditPage.js";

const CarModelRoutes = ({user}) => (
    <Routes>
        <Route index element={<CarModelListPage />} />
        <Route path=':id' element={<CarModelViewPage />} />
        <Route path=':id/edit' element={hasPermission(user, 'update-car-models') ? <CarModelEditPage /> : <CarModelViewPage />} />
    </Routes>
);

export default CarModelRoutes;