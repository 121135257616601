import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchOrder,
    saveOrder,
    changeAttribute,
    updateOrderLine,
    addOrderLine,
    resetStateData,
    lineExists
} from "../../../slices/orderSlice.js";
import {fetchOrderStatuses} from "../../../slices/orderStatusListSlice.js";
import {useParams, useNavigate} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import {setErrorsFromValidation} from "../../../utils/validation.js";
import OrderForm from "./components/OrderForm.js";
import OrderLinesComponent from "./components/OrderLinesComponent.js";
import {getOrderSchema} from "../../../utils/schemas.js";
import OrderNav from "./components/OrderNav.js";
import {fetchPaymentTypes} from "../../../slices/paymentTypeListSlice.js";
import {fetchPartners} from "../../../slices/partnerListSlice.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";

const OrderEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data: order, loading, errors: stateErrors, status} = useSelector((state) => state.order);
    const {data: orderStatuses, loading: orderStatusesLoading} = useSelector((state) => state.orderStatusList)
    const {data: paymentTypes, loading: paymentTypesLoading} = useSelector((state) => state.paymentTypeList)
    const {data: partners, loading: partnersLoading} = useSelector((state) => state.partnerList)

    const [errors, setErrors] = useState({});

    useEffect(() => {
        dispatch(resetStateData());
        dispatch(fetchOrderStatuses());
        dispatch(fetchPaymentTypes());
        dispatch(fetchPartners());  
        if (idIsInteger) {
            dispatch(fetchOrder({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/orders', {state: {message: 'Успешно запазена поръчка!'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);


    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleLineUpdate = (orderLine) => {
        if (isNaN(orderLine.id) && !lineExists(order, orderLine)) {
            dispatch(addOrderLine(orderLine))
        } else {
            dispatch(updateOrderLine(orderLine));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setErrors({});
            await getOrderSchema(order).validate(order, {abortEarly: false});
            await dispatch(saveOrder({id: idIsInteger ? id : null, item: order}));
        } catch (validationErrors) {
            setErrors(setErrorsFromValidation(validationErrors));
        }
    };

    const showForm = () => {
        return !loading && !orderStatusesLoading && !paymentTypesLoading && !partnersLoading;
    }
    return (
        <SingleEditLayout
            title={idIsInteger ? `Поръчка № ${id}` : 'Нова поръчка'}
            showForm={() => showForm()}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={OrderNav}
            navProps={{id: id, activeKey: 'edit'}}
            >
            <OrderForm
                order={order}
                errors={errors}
                orderStatuses={orderStatuses}
                paymentTypes={paymentTypes}
                partners={partners}
                onAttributeChange={handleAttributeChange}
            />
            <OrderLinesComponent
                order={order}
                onLineUpdate={handleLineUpdate}
                errors={errors}
                />
        </SingleEditLayout>
    );
};

export default OrderEditPage;
