
export const getCommonExtraReducers = (builder, fetchItem, saveItem) => {
    return builder
        .addCase(fetchItem.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchItem.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.loading = false;
        })
        .addCase(fetchItem.rejected, (state, action) => {
            state.errors = action.error.message;
            state.loading = false;
        })
        .addCase(saveItem.pending, (state) => {
            state.loading = true;
        })
        .addCase(saveItem.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.status = 'saved'
            state.errors = []
        })
        .addCase(saveItem.rejected, (state, action) => {
            state.errors = action.payload || action.error.message;
            state.loading = false;
        });
}

export const getCommonExtraReducersList = (builder, fetchItems) => {
    return builder
        .addCase(fetchItems.pending, (state) => {
            state.loading = true;
        })
        .addCase(fetchItems.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.pagination.currentPage = action.payload.meta.currentPage;
            state.pagination.lastPage = action.payload.meta.lastPage;
            state.pagination.totalItems = action.payload.meta.total;
            state.loading = false;
        })
        .addCase(fetchItems.rejected, (state, action) => {
            state.error = action.error.message;
            state.loading = false;
        });
}