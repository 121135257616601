import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {fetchCars, resetStateData} from "../../../slices/carListSlice.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import ListHeaderLayout from "../../../components/Layouts/ListHeaderLayout.js";
import CarsTable from "./Components/CarsTable.js";

const CarListPage = () => {
    const dispatch = useDispatch();
    const {data: cars, loading, pagination} = useSelector((state) => state.carList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchCars(searchParams));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const handleQueryChange = (query) => {
        setSearchParams({
            page: 1,
            filters: searchParams.filters,
            search: query
        })
    }

    const showTable = () => {
        return !loading;
    }

    return (
        <div>
            <ListHeaderLayout
                title={'Коли'}
                createLink={'/cars/create/edit'}
                onQueryChange={handleQueryChange}
            />

            <CarsTable showTable={() => showTable()} cars={cars}/>

            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />

        </div>
    );
};

export default CarListPage;
