import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import './SuccessMessageComponent.css'; // Import the CSS for the animation

const SuccessMessageComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [animateOut, setAnimateOut] = useState(false); // To trigger exit animation

    useEffect(() => {
        if (location.state && location.state.message) {
            setMessage(location.state.message);

            // Optionally, you can clear the message after a few seconds
            const timer = setTimeout(() => {
                setAnimateOut(true); // Trigger exit animation
                setTimeout(() => {
                    setMessage(null);
                    navigate(location.pathname, { replace: true, state: null });
                }, 500); // Delay to allow exit animation to complete
            }, 3000); // Message disappears after 3 seconds

            return () => clearTimeout(timer);
        }
    }, [location, navigate]);

    return (
        <div>
            {message && (
                <div className={`header-success-message ${animateOut ? 'exit' : 'enter'}`}>
                    {message}
                </div>
            )}
        </div>
    );
}

export default SuccessMessageComponent;
