import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiClient from "../axios.js";

const initialState = {
    id: null,
    name: "",
    email: ""
};

export const fetchUser = createAsyncThunk(
    'users/fetchUser',
    async ({id}) => {
        const response = await apiClient.get(`/users/${id}`);
        return response.data;
    });

export const saveUser = createAsyncThunk(
    'users/saveUser',
    async ({ id, user }) => {
        let response;
        if (id) {
            response = await apiClient.put(`/users/${id}`, user);
        } else {
            response = await apiClient.post(`/users/`, user);
        }
        return response.data;
    }
);

const userItem = createSlice({
    name: 'userItem',
    initialState: {
        data: {
            initialState
        },
        status: 'idle',
        error: null
    },
    reducers: {
        changeAttribute: (state, action) => {
            state.data[action.payload.attribute] = action.payload.value;
        },
        resetUser: (state) => {
            state.data = initialState
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(saveUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
            })
            .addCase(saveUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default userItem.reducer;
export const {
    changeAttribute,
    resetUser
} = userItem.actions;