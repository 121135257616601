import SuccessMessageComponent from "../SuccessMessageComponent.js";
import ButtonLink from "../Elements/ButtonLink.js";
import React, {useEffect} from "react";
import SearchQueryInputComponent from "../SearchQueryInputComponent.js";
import {useDispatch} from "react-redux";

const ListHeaderLayout = ({
    title,
    createLink,
    onQueryChange,
                    }) => {
    return (
        <>
            <h2>{title}</h2>
            <SuccessMessageComponent/>
            <div className="d-flex justify-content-end">
                <ButtonLink to={createLink}>Добави</ButtonLink>
            </div>
            <div>
                <SearchQueryInputComponent onQueryChange={onQueryChange}/>
            </div>
        </>
    )
}

export default ListHeaderLayout;