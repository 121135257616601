import {Routes, Route} from "react-router-dom";
import CarListPage from "../pages/Cars/List/CarListPage.js";
import CarEditPage from "../pages/Cars/Single/CarEditPage.js";
import CarModelListPage from "../pages/CarModels/List/CarModelListPage.js";
import CarModelViewPage from "../pages/CarModels/Single/CarModelViewPage.js";
import CarViewPage from "../pages/Cars/Single/CarViewPage.js";
import {hasPermission} from "../utils/permissions.js";
import CarFilesPage from "../pages/Cars/Single/CarFilesPage.js";

const CarRoutes = ({
    user
                   }) => (
    <Routes>
            <Route index element={<CarListPage />} />
            <Route path=':id' element={<CarViewPage />} />
            <Route path=':id/edit' element={hasPermission(user, 'update-cars') ? <CarEditPage /> : <CarViewPage />}  />
            <Route path=':id/files' element={hasPermission(user, 'update-cars') ? <CarFilesPage /> : <CarEditPage />}  />
            <Route path='/models' element={<CarModelListPage />} />
            <Route path='/models/:id' element={<CarModelViewPage />} />
    </Routes>
);

export default CarRoutes;