import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: true,
    error: null
}
export const fetchQuantityTypes = fetchItems('quantityTypes/fetchOrderStatuses', '/quantity-types');

const quantityTypeListSlice = createSlice({
    name: 'quantityTypes',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchQuantityTypes)
    },
});

export default quantityTypeListSlice.reducer;