import {Routes, Route} from "react-router-dom";
import RoleListPage from "../pages/Roles/List/RoleListPage.js";
import RolePage from "../pages/Roles/Single/RolePage.js";

const RoleRoutes = () => (
    <Routes>
        <Route index element={<RoleListPage />} />
        <Route path=':id' element={<RolePage />} />
    </Routes>
);

export default RoleRoutes;