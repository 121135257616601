import {Routes, Route} from "react-router-dom";
import ArticleListPage from "../pages/Articles/List/ArticleListPage.js";
import ArticleEditPage from "../pages/Articles/Single/ArticleEditPage.js";
import ArticleOrdersListPage from "../pages/Articles/List/ArticleOrdersListPage.js";
import {hasPermission} from "../utils/permissions.js";
import ArticleViewPage from "../pages/Articles/Single/ArticleViewPage.js";
import ArticleFilesPage from "../pages/Articles/Single/ArticleFilesPage.js";

const ArticleRoutes = ({user}) => (
    <Routes>
        <Route index element={<ArticleListPage />} />
        <Route path=':id' element={<ArticleViewPage />} />
        <Route path=':id/edit' element={hasPermission(user, 'update-articles') ? <ArticleEditPage /> : <ArticleViewPage />}  />
        <Route path=':id/files' element={hasPermission(user, 'update-articles') ? <ArticleFilesPage /> : <ArticleViewPage />}  />
        <Route path=':id/orders' element={<ArticleOrdersListPage />} />
    </Routes>
);

export default ArticleRoutes;