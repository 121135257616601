import { Alert } from 'react-bootstrap';

function ErrorAlertComponent({ error }) {

    const shouldDisplayError = (error) => {
        if (typeof error === 'string' && error.trim() !== '') {
            return true;
        }
        if (Array.isArray(error) && error.length > 0) {
            return true;
        }
        return typeof error === 'object' && error !== null && Object.keys(error).length > 0;
    };

    return (
        <>
            {shouldDisplayError(error) && (
                <Alert variant="danger" className="text-center d-inline-block">
                    {Array.isArray(error) && error.length > 0 ? (
                        // If error is a non-empty array, iterate and display each item
                        error.map((err, index) => (
                            <p key={index}>{err}</p>
                        ))
                    ) : typeof error === 'object' ? (
                        // If error is a non-empty object, iterate over its values and display each value
                        Object.values(error).map((value, index) => (
                            <p key={index}>{value}</p>
                        ))
                    ) : typeof error === 'string' && error.trim() !== '' ? (
                        // If error is a non-empty string, display it directly
                        <p>{error}</p>
                    ) : null}
                </Alert>
            )}
        </>
    );
}

export default ErrorAlertComponent;
