import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchArticle,
    resetStateData,
} from "../../../slices/articleSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import SingleFilesLayout from "../../../components/Layouts/SingleFilesLayout.js";
import ArticleNav from "./components/ArticleNav.js";

const ArticleFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: article, loading, errors: stateErrors} = useSelector((state) => state.article);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchArticle({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const showPage = () => {
        return !loading;
    }

    const getTitle = () => {
        return  `Артикул № ${id}`;
    }

    return (
        <SingleFilesLayout
            title={getTitle()}
            showPage={() => showPage()}
            stateErrors={stateErrors}
            navComponent={ArticleNav}
            navProps={{id: id, activeKey: 'files'}}
            item = {article}
            fetchItem={fetchArticle}
            dispatch={dispatch}
            itemsPath={'articles'}
        />
    );
};



export default ArticleFilesPage;
