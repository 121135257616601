import { Navigate, Route, Routes } from 'react-router-dom';
import {hasPermission} from "./utils/permissions.js";
import OrderRoutes from "./routes/OrderRoutes.js";
import ArticleRoutes from "./routes/ArticleRoutes.js";
import UserRoutes from "./routes/UserRoutes.js";
import ClientRoutes from "./routes/ClientRoutes.js";
import CarRoutes from "./routes/CarRoutes.js";
import CarModelRoutes from "./routes/CarModelRoutes.js";
import CarManufacturerRoutes from "./routes/CarManufacturerRoutes.js";
import RoleRoutes from "./routes/RoleRoutes.js";
import Home from "./pages/Home.js";
import Logout from "./pages/Logout.js";

const ProtectedRoutes = ({ user }) => {
    if (user === null) {
        return <Navigate to="/" />;
    }

    return (
        <Routes>
            <Route path={'/logout'} element={<Logout />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />

            {hasPermission(user, 'get-orders') && (
                <Route path="/orders/*" element={<OrderRoutes user={user} />} />
            )}

            {hasPermission(user, 'get-articles') && (
                <Route path="/articles/*" element={<ArticleRoutes user={user} />} />
            )}

            {hasPermission(user, 'get-users') && (
                <Route path="/users/*" element={<UserRoutes />} />
            )}

            {hasPermission(user, 'get-cars') && (
                <Route path="/cars/*" element={<CarRoutes user={user}/>} />
            )}

            {hasPermission(user, 'get-car-models') && (
                <Route path="/car-models/*" element={<CarModelRoutes user={user} />} />
            )}

            {hasPermission(user, 'get-car-models') && (
                <Route path="/car-manufacturers/*" element={<CarManufacturerRoutes user={user} />} />
            )}

            {hasPermission(user, 'get-clients') && (
                <Route path="/clients/*" element={<ClientRoutes user={user}/>} />
            )}

            {hasPermission(user, 'get-roles') && (
                <Route path="/roles/*" element={<RoleRoutes />} />
            )}
        </Routes>
    );
};

export default ProtectedRoutes;
