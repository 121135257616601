import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchArticle, resetStateData, saveArticle} from "../../../slices/articleSlice.js";
import {loadInitialData, processIdInformation, processSubmit} from "../../../utils/functions.js";
import ArticleForm from "./components/ArticleForm.js";
import {fetchQuantityTypes} from "../../../slices/quantityTypeSlice.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import ArticleNav from "./components/ArticleNav.js";
import {getArticleSchema} from "../../../utils/schemas.js";
import {fetchArticleTypes} from "../../../slices/articleTypeListSlice.js";

const ArticleEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: article, loading, errors: stateErrors, status} = useSelector((state) => state.article);
    const {data: quantityTypes, loading: quantityTypesLoading} = useSelector((state) => state.quantityTypeStatusList);
    const {data: articleTypes, loading: articleTypesLoading} = useSelector((state) => state.articleTypeList);

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        loadInitialData(dispatch, resetStateData, fetchArticle, id, [fetchQuantityTypes, fetchArticleTypes])
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/articles', {state: {message: 'Успешно запазен артикул'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSettingsChange = (attribute, value) => {
        const settings = {...article.settings};
        settings[attribute] = value;
        dispatch(changeAttribute({attribute: 'settings', value: settings}));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, getArticleSchema(article), saveArticle, dispatch, article);
    };

    return (
        <SingleEditLayout
            title={idIsInteger ? `Артикул № ${id}` : 'Нов Артикул'}
            showForm={() => !loading && !quantityTypesLoading && !articleTypesLoading}
            onSubmit={handleSubmit}
            navComponent={ArticleNav}
            navProps={{id: id, activeKey: 'edit'}}
            stateErrors={stateErrors}
        >
            <ArticleForm
                article={article}
                onSubmit={handleSubmit}
                onAttributeChange={handleAttributeChange}
                errors={errors}
                quantityTypes={quantityTypes}
                articleTypes={articleTypes}
                onSettingsChange={handleSettingsChange}
            />
        </SingleEditLayout>
    );
};

export default ArticleEditPage;
