import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

const SearchQueryInputComponent = ({
    onQueryChange
                                   }) => {
    const handleQueryChange = (e) => {
        const value = e.target.value;

        // Set a timeout of 200ms before updating the query state
        clearTimeout(window.debounceTimeout); // Clear any existing timeout
        window.debounceTimeout = setTimeout(() => {
            if (value.length > 1 || !value) {
                onQueryChange(value);
            }
        }, 200);
    };

    return (
        <Form className={'mt-3 mb-3'}>
            <Row className="align-items-center">
                <Col xs="auto">
                    <Form.Label className="mr-2">Търси:</Form.Label>
                </Col>
                <Col xs="auto">
                    <Form.Control onChange={handleQueryChange}/>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchQueryInputComponent;