import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import '../../css/DateInput.css';

const  DateInput = (
    {
        label,
        dateValue,
        onDateValueChange,
        name,
        errors
    }) => {
    const [date, setDate] = useState({day: '', month: '', year: ''});

    const monthRef = useRef(null);
    const yearRef = useRef(null);

    useEffect(() => {
        if (dateValue) {
            const [year, month, day] = dateValue.split('-');
            setDate({day: day, month: month, year: year})
        }
    }, []);

    useEffect(() => {
        const newDateValue = `${date.year}-${date.month}-${date.day}`;
        if (newDateValue !== dateValue) {
            onDateValueChange(name, newDateValue === '--' ? '' : newDateValue);
        }
    }, [date]);

    // Handle change for the day input
    const handleDayChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Only allow digits
        if (value.length <= 2) {
            setDate({day: value, month: date.month, year: date.year});
            if (value.length === 2) {
                monthRef.current.focus(); // Move to month input when day is complete
            }
        }
    };

    // Handle change for the month input
    const handleMonthChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Only allow digits
        if (value.length <= 2) {
            setDate({day: date.day, month: value, year: date.year});
            if (value.length === 2) {
                yearRef.current.focus(); // Move to year input when month is complete
            }
        }
    };

    // Handle change for the year input
    const handleYearChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Only allow digits
        if (value.length <= 4) {
            setDate({day: date.day, month: date.month, year: value});
        }
    };

    return (
        <>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type="hidden"
                isInvalid={!!errors[name]}
                value={`${date.day}-${date.month}-${date.year}`} // Combine day, month, year for validation
            />
            <Form.Control.Feedback type="invalid">
                <b>{errors[name]}</b>
            </Form.Control.Feedback>
            <Form.Group className="d-flex align-items-center mb-2">

                <Form.Control
                    type="text"
                    value={date.day}
                    onChange={handleDayChange}
                    placeholder="ден"
                    maxLength={2}
                    className="date-part-input"
                />
                <span className="dot-separator">.</span>
                <Form.Control
                    type="text"
                    ref={monthRef}
                    value={date.month}
                    onChange={handleMonthChange}
                    placeholder="мес"
                    maxLength={2}
                    className="date-part-input"
                />
                <span className="dot-separator">.</span>
                <Form.Control
                    type="text"
                    ref={yearRef}
                    value={date.year}
                    onChange={handleYearChange}
                    placeholder="год"
                    maxLength={4}
                    className="date-part-input year-input"
                />
                <span>г.</span>
            </Form.Group>
        </>
    );
};

export default DateInput;
