import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import {useParams, Navigate, useNavigate} from "react-router-dom";
import {changeAttribute, fetchUser, resetUser, saveUser} from "../../../slices/userSlice.js";
import {Badge} from "react-bootstrap";

const UserPage = () => {
    const { id } = useParams();
    const idIsInteger = /^\d+$/.test(id);
    const isValidId = id === 'create' || idIsInteger;
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const status = useSelector((state) => state.user.status);
    const navigate = useNavigate();

    useEffect(() => {
        if (idIsInteger) {
            dispatch(fetchUser({id: id}));
        } else {
            dispatch(resetUser());
        }
    }, [id, dispatch, idIsInteger]);

    const handleAttributeChange = (event) => {
        const attribute = event.target.name;
        const value = event.target.value;
        dispatch(changeAttribute({attribute, value}));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(saveUser({ id: idIsInteger ? id : null, user: user }));
            navigate('/users');
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    return (
        <div>
            {status !== 'loading' && (
                <div>
                    {!isValidId && (
                        <Navigate to="/users" />
                    )}
                    {idIsInteger ?
                        <h2>Потребител № {id}</h2>
                        :
                        <h2>Нова потребител</h2>
                    }
                    <div>
                        {(idIsInteger && user.id) || !idIsInteger ? (
                            <div>
                                <Form onSubmit={handleSubmit}>
                                    <Button variant="success" type="submit">
                                        Запази
                                    </Button>
                                    <Form.Group className="mb-3" controlId="formHorizontalEmail">
                                        <Row className="mb-2">
                                            <Form.Label column sm={1}>Име</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    name="name"
                                                    value={user.name}
                                                    onChange={handleAttributeChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                                <div>
                                    <p>Достъп</p>
                                    {user.permissions.map((permission) => (
                                        <Badge className="m-1" bg="warning">{permission.name}</Badge>
                                    ))}
                                </div>
                                <div>
                                    <p>Роли</p>
                                    {user.roles.map((role) => (
                                        <Badge className="m-1" bg="secondary">{role}</Badge>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p>Loading</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserPage;
