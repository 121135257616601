import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from "../axios.js";

// Async thunk to fetch orders
export const fetchUsers = createAsyncThunk(
    'users/FetchUsers',
    async ({search = ''} = {}) => {
        let queryParams = {};
        if (search) {
            queryParams['search'] = search;
        }
        let path = '/users';
        let queryParamsString = new URLSearchParams(queryParams).toString();

        if (queryParamsString) {
            path += `?${queryParamsString}`;
        }

        const response = await apiClient.get(path);
        return response.data;
    });

const userListSlice = createSlice({
    name: 'users',
    initialState: { data: [], status: 'idle', error: null },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload.data;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default userListSlice.reducer;