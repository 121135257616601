import {Button, Card, Table} from "react-bootstrap";
import {toFloatBgn} from "../../../../utils/functions.js";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";

const ArticlesTable = ({showTable, articles}) => {

    return (
        <Card>
            {showTable() ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>Име</th>
                        <th>Код</th>
                        <th>Прозиводител</th>
                        <th>Продажна цена</th>
                        <th>Покупна цена</th>
                        <th>Наличност</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {articles.map((article) => (
                        <tr key={article.id}>
                            <td>{article.id}</td>
                            <td>{article.name}</td>
                            <td>{article.skuNumber}{!article.isNew ? <i> (втора ръка)</i> : ''}</td>
                            <td>{article.manufacturer}</td>
                            <td>{toFloatBgn(article.sellingPrice)}</td>
                            <td>{toFloatBgn(article.costPrice)}</td>
                            <td>{article.stock}</td>
                            <td>
                                <Button variant="link">
                                    <Link to={`/articles/${article.id}/edit`}>Редактирай</Link>
                                </Button>
                                <Button variant="link">
                                    <Link to={`/articles/${article.id}/orders`}>Поръчки</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <LoadingComponent/>

            )}
        </Card>
    )
}

export default ArticlesTable;