import {Routes, Route} from "react-router-dom";
import OrderListPage from "../pages/Orders/List/OrderListPage.js";
import OrderEditPage from "../pages/Orders/Single/OrderEditPage.js";
import OrderViewPage from "../pages/Orders/Single/OrderViewPage.js";
import OrderFilesPage from "../pages/Orders/Single/OrderFilesPage.js";
import {hasPermission} from "../utils/permissions.js";

const OrderRoutes = ({
    user
                     }) => (
    <Routes>
        <Route index element={<OrderListPage />} />
        <Route path=':id' element={<OrderViewPage />} />
        <Route path=':id/edit' element={hasPermission(user, 'update-orders') ? <OrderEditPage /> : <OrderViewPage />}  />
        <Route path=':id/files' element={hasPermission(user, 'update-orders') ? <OrderFilesPage /> : <OrderViewPage />}  />
    </Routes>
);

export default OrderRoutes;