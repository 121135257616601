import {Button, Card, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";

const ClientsTable = ({
    showTable,
    clients
                      }) => {
    return (
        <Card>
            {showTable() ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>Име</th>
                        <th>Email</th>
                        <th>Телефон</th>
                        <th>ЕГН/ЕИК</th>
                        <th>Тип</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map((client) => (
                        <tr key={client.id}>
                            <td>{client.id}</td>
                            <td>{client.name}</td>
                            <td>{client.email}</td>
                            <td>{client.phoneNumber}</td>
                            <td>{client.identificationId}</td>
                            <td>{client.clientType.name}</td>
                            <td>
                                <Button variant="link">
                                    <Link to={`/clients/${client.id}`}>Преглед</Link>
                                </Button>
                                <Button variant="link">
                                    <Link to={`/clients/${client.id}/edit`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </Table>
            ) : (
                <LoadingComponent/>

            )}
        </Card>
    )
}

export default ClientsTable;