import {Routes, Route} from "react-router-dom";
import UserListPage from "../pages/Users/List/UserListPage.js";
import UserPage from "../pages/Users/Single/UserPage.js";

const UserRoutes = () => (
    <Routes>
        <Route index element={<UserListPage />} />
        <Route path=':id' element={<UserPage />} />
    </Routes>
);

export default UserRoutes;