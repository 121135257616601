import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState} from "react";
import ClientForm from "../../../Clients/Single/Components/ClientForm.js";
import {useDispatch, useSelector} from "react-redux";
import { processSubmit} from "../../../../utils/functions.js";
import {changeAttribute, resetStateData, saveClient, setData} from "../../../../slices/clientSlice.js";
import {fetchClientTypes} from "../../../../slices/clientTypeListSlice.js";
import Button from "react-bootstrap/Button";
import {getClientSchema} from "../../../../utils/schemas.js";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchClients} from "../../../../slices/clientListSlice.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import ErrorAlertComponent from "../../../../components/ErrorAlertComponent.js";

const AddClientModal = ({
                            show,
                            closeModal,
                            onNewClientUpdate,
                            orderClient = null
                        }) => {
    const {data: clientTypes, loading: clientTypesLoading} = useSelector((state) => state.clientTypeList)
    const {data: client, errors: stateErrors} = useSelector((state) => state.client);

    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});
    const [isNewClient, setIsNewClient] = useState(false);

    const clientSchema = getClientSchema();

    useEffect(() => {
        if (!orderClient) {
            dispatch(resetStateData())
        } else {
            dispatch(setData(orderClient))
        }
        if (!clientTypes.length) {
            dispatch(fetchClientTypes())
        }
    }, [dispatch, orderClient, show]);

    useEffect(() => {
        if (isNewClient && client.id) {
            handleClientUpdateAndClose( client);
        }
    }, [client, isNewClient]);

    const handleIsNewClientToggle = () => {
        dispatch(resetStateData());
        setIsNewClient(!isNewClient);
    }

    const handleClose = () => {
        setErrors({});
        setIsNewClient(false);
        closeModal();
    };

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

      const handleClientSearchChange = (name, searchClient) => {
        dispatch(setData(searchClient));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isNewClient) {
            await processSubmit(setErrors, clientSchema, saveClient, dispatch, client);
        } else if (stateErrors.length === 0) {
            handleClientUpdateAndClose(client);
        }
    };

    const handleClientUpdateAndClose = (item) => {
        onNewClientUpdate(item);
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} animation={false} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Клиент</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <SearchAutoCompleteComponent
                            onItemChange={handleClientSearchChange}
                            item={isNewClient ? null : client}
                            name={'client'}
                            label={''}
                            fetchItems={fetchClients}
                            reducer={'clientList'}
                            errors={errors}
                            itemLabel={'searchLabel'}
                            disabled={isNewClient}
                        />
                        <div className={'mt-2'}>
                            <FormCheckbox name={'isNewClient'} onCheckboxChange={handleIsNewClientToggle}
                                          isChecked={isNewClient} label={'Нов Клиент'}/>
                        </div>
                    </Col>
                    <Col md={12}>
                        <ErrorAlertComponent error={stateErrors}/>

                        {isNewClient && !clientTypesLoading && (
                            <ClientForm
                                        onAttributeChange={handleAttributeChange}
                                        errors={errors}
                                        client={client}
                                        clientTypes={clientTypes}
                                        onSubmit={handleSubmit}
                            />

                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>Откажи</Button>
                {(isNewClient || client?.id) && (
                    <Button variant="success" onClick={handleSubmit}>Запази</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default AddClientModal;