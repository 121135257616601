import {getValidationSchema} from "./validation.js";

export const getOrderSchema = (order) => {
    const schema = {
        car: ['object'],
        client: ['object'],
        additionalInformation: ['string'],
        total: ['float'],
        orderLines: ['array'],
        orderStatus: ['object'],
        partner: ['object'],
        startDate: ['date'],
        endDate: ['date'],
        invoiceDate: ['date']
    }

    if (order.orderStatus?.settings?.needsEndDate) {
        schema.endDate.push('required');
    }

    if (order.orderStatus?.settings?.needsInvoiceData) {
        schema.invoiceDate.push('required');
        if (!order.noInvoiceNumber) {
            schema.invoiceNumber = ['required']
        }
    }

    return getValidationSchema(schema)
}

export const getOrderLineSchema = (orderLine) => {
    const schema = {
        article: ['object'],
        quantity: ['required', 'float'],
        price: ['required', 'float'],
        discount: ['integer'],
        totalPrice: ['required', 'float']
    }
    if (orderLine.article.settings?.mandatoryLineNote === true) {
        schema.additionalInformation = ['required']
    }
    return getValidationSchema(schema)
}
export const getPaintOrderLineSchema = () => getValidationSchema({
    article: ['object'],
    damageLevel: ['object']
})

export const getClientSchema = () => getValidationSchema({
    name: ['required'],
    email: ['email'],
    phoneNumber: ['required'],
    identificationId: ['required'],
    clientType: ['required', 'object'],
    address: ['required'],
    city: ['required'],
    country: ['required']
})

export const getCarSchema = () => getValidationSchema({
    carModel: ['required', 'object'],
    registrationNumber: ['required', ['regex', '^[ABCEHKMOPTXY0-9]+$', 'Грешен рег. номер. Провери латиница или премахни ненужни знаци']],
    client: ['required', 'object']
})

export const getArticleSchema = (article) => {
    const schema = {
        name: ['required', ['min', 2], ['max', 100]],
        stock: ['required', 'integer'],
        sellingPrice: ['required', 'float'],
        costPrice: ['required', 'float'],
        quantityType: ['object'],
        articleType: ['object']
    };

    if (article.articleType?.label === 'product') {
        schema.skuNumber = ['required'];
        schema.manufacturer = ['required'];
        schema.oemNumber = ['required'];
    }
    return getValidationSchema(schema);
};