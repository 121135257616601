import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import {useParams, Navigate, useNavigate} from "react-router-dom";
import {changeAttribute, fetchRole, resetStateData, saveRole} from "../../../slices/roleSlice.js";
import {Badge} from "react-bootstrap";

const RolePage = () => {
    const { id } = useParams();
    const idIsInteger = /^\d+$/.test(id);
    const isValidId = id === 'create' || idIsInteger;
    const dispatch = useDispatch();
    const role = useSelector((state) => state.role.data);
    const status = useSelector((state) => state.role.status);
    const navigate = useNavigate();

    useEffect(() => {
        if (idIsInteger) {
            dispatch(fetchRole({id: id}));
        } else {
            dispatch(resetStateData());
        }
    }, [id, dispatch, idIsInteger]);

    const handleAttributeChange = (event) => {
        const attribute = event.target.name;
        const value = event.target.value;
        dispatch(changeAttribute({attribute, value}));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(saveRole({ id: idIsInteger ? id : null, role: role }));
            navigate('/users/roles');
        } catch (error) {
            console.error('Error updating role:', error);
        }
    };

    return (
        <div>
            {status !== 'loading' && (
                <div>
                    {!isValidId && (
                        <Navigate to="/roles" />
                    )}
                    {idIsInteger ?
                        <h2>Роля № {id}</h2>
                        :
                        <h2>Нова потребител</h2>
                    }
                    <div>
                        {(idIsInteger && role.id) || !idIsInteger ? (
                            <div>
                                <Form onSubmit={handleSubmit}>
                                    <Button variant="success" type="submit">
                                        Запази
                                    </Button>
                                    <Form.Group className="mb-3" controlId="formHorizontalEmail">
                                        <Row className="mb-2">
                                            <Form.Label column sm={1}>Име</Form.Label>
                                            <Col sm={10}>
                                                <Form.Control
                                                    name="name"
                                                    value={role.name}
                                                    onChange={handleAttributeChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Form>
                                <div>
                                    <p>Достъп</p>
                                    {role.permissions.map((permission) => (
                                        <Badge key={permission.id} className="m-1" bg="warning">{permission.name}</Badge>
                                    ))}
                                </div>
                                <div>
                                    <p>Потребители</p>
                                    {role.users.map((user) => (
                                        <Badge key={user.id} className="m-1" bg="secondary">{user.name}</Badge>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p>Loading</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RolePage;
