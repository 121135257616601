import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useState, useEffect} from "react";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchClientCars, resetStateData} from "../../../../slices/carListSlice.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {fetchClients} from "../../../../slices/clientListSlice.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import DateInput from "../../../../components/Elements/DateInput.js";
import {Button} from "react-bootstrap";
import AddClientModal from "./AddClientModal.js";
import {useDispatch, useSelector} from "react-redux";
import AddCarModal from "./AddCarModal.js";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";

const OrderForm = (
    {
        order,
        errors,
        orderStatuses,
        paymentTypes,
        onAttributeChange,
        partners
    }) => {
    const dispatch = useDispatch();
    const {data: cars, loading: carsLoading} = useSelector((state) => state.carList);
    const [showNewClientModal, setShowNewClientModal] = useState(false);
    const [showNewCarModal, setShowNewCarModal] = useState(false);
    const closeNewClientModal = () => {
        setShowNewClientModal(false)
    }

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        if (order.client?.id) {
            dispatch(fetchClientCars({id: order.client?.id}));
        }
    }, [order.client?.id, order.car?.id])

    useEffect(() => {
        if (cars.length === 1) {
            onAttributeChange('car', cars[0])
        }
    }, [cars]);

    const handleClientUpdate = async (value) => {
        onAttributeChange('client', value);
        onAttributeChange('invoiceClient', value);
        onAttributeChange('car', null);
    }

    const handleCarUpdate = (value) => {
        onAttributeChange('car', value)
    }

    const closeAddCarModal = () => {
        setShowNewCarModal(false)
    }

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col sm={4} style={{border: errors?.client ? '2px solid red' : 'none'}}>
                        <span>Клиент <br/>{order.client ? (order.client.name) : (
                            <span className={'text-danger text-decoration-underline fw-bold'}>! няма !</span>)}</span>
                        <Button className={'d-inline-block float-end'}
                                onClick={() => setShowNewClientModal(true)}>{order.client ? 'Промени' : 'Добави'}</Button>
                    </Col>
                    <Col sm={4}>
                        <SearchAutoCompleteComponent
                            onItemChange={onAttributeChange}
                            item={order.invoiceClient}
                            name={'invoiceClient'}
                            label={'Клиент фактура'}
                            fetchItems={fetchClients}
                            reducer={'clientList'}
                            errors={errors}
                            itemLabel={'searchLabel'}
                            inline={true}
                        />
                    </Col>
                    <Col md={4}>
                        <FormInput name={'insurerClaimId'} label={'Номер на щета'} errors={errors}
                                   onChange={onAttributeChange}
                                   value={order.insurerClaimId}
                        />
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    <Col sm={4} style={{border: errors?.car ? '2px solid red' : 'none'}}>
                        <span>Автомобил <br/> {order.car?.id ? (order.car.searchLabel) : (
                            <span className={'text-danger text-decoration-underline fw-bold'}>! няма !</span>)}</span>
                        <Button className={'d-inline-block float-end'} onClick={() => setShowNewCarModal(true)}
                                disabled={!order.client || carsLoading}>{order.car ? 'Промени' : 'Добави'}</Button>
                    </Col>
                    <Col sm={4}>
                        <FormNumericInput name={'mileage'} label={'Пробег'} errors={errors} onChange={onAttributeChange}
                                          value={order.mileage} step={1} min={0}
                        />
                    </Col>
                </Row>
                <Row>

                    <Col sm={4}>
                        <SelectInput
                            label={'Изпълнител'}
                            value={order.partner ? order.partner.id : ''}
                            name={'partner'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={partners}
                        />
                    </Col>
                    <Col sm={4}>
                        <SelectInput
                            label={'Статус'}
                            value={order.orderStatus ? order.orderStatus.id : ''}
                            name={'orderStatus'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={
                                order.id === null
                                    ? orderStatuses.filter(status => status.label !== "canceled")
                                    : orderStatuses
                            }
                        />
                    </Col>
                    <Col sm={4}>
                        <SelectInput
                            label={'Начин плащане'}
                            value={order.paymentType ? order.paymentType.id : ''}
                            name={'paymentType'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={paymentTypes}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <DateInput label={'Дата приемане'} dateValue={order.startDate} name={'startDate'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={4}>
                        <DateInput label={'Дата издаване'} dateValue={order.endDate} name={'endDate'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={4}>
                        <DateInput label={'Дата фактура'} dateValue={order.invoiceDate} name={'invoiceDate'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                        <Row>
                        <Col md={4}>
                        <FormInput errors={errors} onChange={onAttributeChange} name={'invoiceNumber'}
                                   value={order.invoiceNumber}
                                   label={'Номер фактура'}
                        />
                        </Col>
                        <Col md={8}>
                            <FormCheckbox name={'noInvoiceNumber'} label={'Без номер фактура'} onCheckboxChange={onAttributeChange} isChecked={order.noInvoiceNumber} />
                        </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col sm={8}>
                        <FormInput errors={errors} onChange={onAttributeChange} name={'additionalInformation'}
                                   value={order.additionalInformation}
                                   label={'Бележка'} as={'textarea'}
                        />
                    </Col>
                </Row>
                <AddClientModal show={showNewClientModal}
                                closeModal={closeNewClientModal}
                                orderClient={order.client}
                                onNewClientUpdate={handleClientUpdate}
                />
                <AddCarModal show={showNewCarModal}
                             closeModal={closeAddCarModal}
                             orderCar={order.car}
                             onCarUpdate={handleCarUpdate}
                             clientCars={cars}
                             client={order.client}
                             clientCarsLoading={carsLoading}
                />
            </Form.Group>

        </Form>
    )
}

export default OrderForm;