import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchClient, resetStateData, saveClient} from "../../../slices/clientSlice.js";
import {loadInitialData, processIdInformation, processSubmit} from "../../../utils/functions.js";
import ClientForm from "./Components/ClientForm.js";
import {fetchClientTypes} from "../../../slices/clientTypeListSlice.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import ClientNav from "./Components/ClientNav.js";
import {getClientSchema} from "../../../utils/schemas.js";

const ClientEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);
    const dispatch = useDispatch();
    const {data: client, loading, errors: stateErrors, status} = useSelector((state) => state.client);
    const {data: clientTypes, loading: clientTypesLoading} = useSelector((state) => state.clientTypeList)
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const clientSchema = getClientSchema();

    useEffect(() => {
        loadInitialData(dispatch, resetStateData, fetchClient, id, [fetchClientTypes])
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/clients', {state: {message: 'Успешно запазен клиент'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, clientSchema, saveClient, dispatch, client);
    };

    return (
        <SingleEditLayout
            title={idIsInteger ? `Клиент № ${id}` : 'Нов клиент'}
            showForm={() => !loading && !clientTypesLoading}
            onSubmit={handleSubmit}
            stateErrors={stateErrors}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'edit'}}
        >
            <ClientForm
                        onAttributeChange={handleAttributeChange}
                        errors={errors}
                        client={client}
                        clientTypes={clientTypes}
                        onSubmit={handleSubmit}
            />
        </SingleEditLayout>
    );
};

export default ClientEditPage;
