import {createSlice} from '@reduxjs/toolkit';
import {fetchItem, saveItem} from "../utils/sliceFunctions.js";
import {changeAttributeReducer, resetStateDataReducer} from "../utils/commonReducers.js";
import {getCommonExtraReducers} from "../utils/commonExtraReducers.js";
import {calculateOrderTotalPrice} from "../utils/functions.js";

const initialState = {
    data: {
        id: null,
        additionalInformation: "",
        createdAt: null,
        updatedAt: null,
        orderLines: [],
        car: null,
        client: null,
        invoiceClient: null,
        orderStatus: null,
        paymentType: null,
        partner: null,
        startDate: '',
        endDate: '',
        invoiceDate: '',
        invoiceNumber: '',
        noInvoiceNumber: false,
        total: 0,
        totalWithoutVat: 0,
        vatOfTotal: 0,
        files: [],
        mileage: null,
        insurerClaimId: ""
    },
    loading: false,
    status: 'idle',
    errors: []
};

export const fetchOrder = fetchItem('order/fetchOrder', '/orders')

export const saveOrder = saveItem('order/saveOrder', '/orders');

export const lineExists = (order, orderLine) => {
    return order.orderLines.some(item => item.id === orderLine.id);
};



const orderSlice = createSlice({
    name: 'orderItem',
    initialState: initialState,
    reducers: {
        changeAttribute: changeAttributeReducer,
        resetStateData: resetStateDataReducer(initialState),
        removeOrderLine: (state, action) => {
            state.data.orderLines = state.data.orderLines.filter(item =>
                item.id !== action.payload.id
            );
            state.data.total = calculateOrderTotalPrice(state.data.orderLines);
        },
        updateOrderLine: (state, action) => {
            state.data.orderLines = state.data.orderLines.map(item =>
                item.id === action.payload.id ? action.payload : item
            );

            state.data.total = calculateOrderTotalPrice(state.data.orderLines);
        },
        addOrderLine: (state, action) => {
            action.payload.isActive = true;
            state.data.orderLines.push(action.payload);
            state.data.total = calculateOrderTotalPrice(state.data.orderLines);
        }
    },
    extraReducers: (builder) => {
        getCommonExtraReducers(builder, fetchOrder, saveOrder)
    },
});

export const getArticleAvailableStock = (order, article) => {
    let availableStock = article.availableStock;

    order.orderLines.forEach(orderLine => {
        if (orderLine.article.id === article.id) {
            availableStock -= orderLine.quantity;
        }
    });

    return availableStock;
};

export default orderSlice.reducer;
export const {
    removeOrderLine,
    updateOrderLine,
    addOrderLine,
    changeAttribute,
    resetStateData
} = orderSlice.actions;