import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ClientNav from "./Components/ClientNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import PaginationComponent from "../../../components/PaginationComponent.js";
import CarsTable from "../../Cars/List/Components/CarsTable.js";
import {fetchClientCars, resetStateData} from "../../../slices/carListSlice.js";

const ClientCarsPage = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {data: cars, loading, pagination} = useSelector((state) => state.carList);
    const [searchParams, setSearchParams] = useState({page: 1, search: '', filters: {}})

    useEffect(() => {
        dispatch(resetStateData());
    }, []);

    useEffect(() => {
        dispatch(fetchClientCars({...searchParams, id: id}));
    }, [searchParams]);

    const handlePageChange = (page) => {
        setSearchParams({
            page: page,
            filters: searchParams.filters,
            search: searchParams.search
        })
    };

    const showTable = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={`Клиент № ${id}`}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'cars'}}
            showPage={() => !loading}
        >
            <CarsTable cars={cars} showTable={() => showTable()} />
            <PaginationComponent
                onPageChange={handlePageChange}
                pagination={pagination}
            />

        </SingleViewLayout>
    )
};

export default ClientCarsPage;