import { createSlice } from '@reduxjs/toolkit';
import {fetchItem} from "../utils/sliceFunctions.js";

export const fetchUser = fetchItem('usersMe', '/users/me')

const initialState = {
    user: null,
    status: 'idle'
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.user = null;
                state.status = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.status = 'idle';
                localStorage.setItem('isAuthenticated', 'true')
            })
            .addCase(fetchUser.rejected, (state) => {
                state.user = null;
                state.status = 'rejected';
                localStorage.setItem('isAuthenticated', 'false')
            });
    }
});

export const {
    login,
    logout,
    setPermissions,
    checkAuth,
    hasPermission
} = authSlice.actions;

export default authSlice.reducer;
