import {setErrorsFromValidation} from "./validation.js";

export const processIdInformation = (id) => {
    const idIsInteger = /^\d+$/.test(id);
    const isValidId = id === 'create' || idIsInteger;

    return {
        idIsInteger: idIsInteger,
        isValidId: isValidId
    };
}

export const calculateOrderTotalPrice = (orderLines) => {
    return orderLines
        .filter(item => item.isActive)
        .reduce((acc, item) => acc + parseFloat(item.totalPrice), 0);
};

export const toFloat = (number) => {
    return number.toFixed(2)
}

export const toFloatBgn = (number) => {
    return number.toFixed(2) + ' лв.'
}

export const getVatFloatBgn = (priceWithVat) => {
    return toFloatBgn(priceWithVat / 6);
}

export const processSubmit = async (
    setErrors,
    schema,
    saveItem,
    dispatch,
    item,
) => {
    try {
        setErrors({});
        await schema.validate(item, {abortEarly: false});
        await dispatch(saveItem({id: item.id, item: item}));
    } catch (validationErrors) {
        setErrors(setErrorsFromValidation(validationErrors));
    }
}

export const loadInitialData = (
    dispatch,
    resetStateData,
    fetchItem,
    id,
    otherItemsFetchers = []
) => {
    const {idIsInteger} = processIdInformation(id);

    dispatch(resetStateData());
    otherItemsFetchers.forEach(fetcher => {
        dispatch(fetcher());
    });
    if (idIsInteger) {
        dispatch(fetchItem({id: id}));
    }
}