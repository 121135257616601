import React from 'react';
import './LoadingComponent.css'; // Import the CSS file

const LoadingComponent = () => {
    return (
        <div className="loading-overlay">
            <div className="loading-spinner"></div>
        </div>
    );
};

export default LoadingComponent;
