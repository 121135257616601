import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

const CarManufacturerNav = ({
                                id,
                                activeKey
                            }) => {

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            {id !== 'create' && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'view'} to={`/car-manufacturers/${id}`}>Преглед</Nav.Link>
                </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/car-manufacturers/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default CarManufacturerNav;