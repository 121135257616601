import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

const ClientNav = (
    {
        id,
        activeKey
    }) => {

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="view">
            {id !== 'create' && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'view'} to={`/clients/${id}`}>Преглед</Nav.Link>
                </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/clients/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            {id !== 'create' && (
                <>
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'cars'} to={`/clients/${id}/cars`}>Коли</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} eventKey={'orders'} to={`/clients/${id}/orders`}>Поръчки</Nav.Link>
                    </Nav.Item>
                </>
            )}
        </Nav>
    )
};

export default ClientNav;