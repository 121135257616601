import {Routes, Route} from "react-router-dom";
import CarManufacturerListPage from "../pages/CarManufacturers/List/CarManufacturerListPage.js";
import CarManufacturerEditPage from "../pages/CarManufacturers/Single/CarManufacturerEditPage.js";
import {hasPermission} from "../utils/permissions.js";
import CarManufacturerViewPage from "../pages/CarManufacturers/Single/CarManufacturerViewPage.js";

const CarManufacturerRoutes = ({
    user
                               }) => (
    <Routes>
        <Route index element={<CarManufacturerListPage />} />
        <Route path=':id' element={<CarManufacturerViewPage />} />
        <Route path=':id/edit' element={hasPermission(user, 'update-car-models') ? <CarManufacturerEditPage /> : <CarManufacturerViewPage />} />
    </Routes>
);

export default CarManufacturerRoutes;