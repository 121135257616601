import {Routes, Route} from "react-router-dom";
import ClientListPage from "../pages/Clients/List/ClientListPage.js";
import ClientEditPage from "../pages/Clients/Single/ClientEditPage.js";
import {hasPermission} from "../utils/permissions.js";
import ClientViewPage from "../pages/Clients/Single/ClientViewPage.js";
import ClientOrdersPage from "../pages/Clients/Single/ClientOrdersPage.js";
import ClientCarsPage from "../pages/Clients/Single/ClientCarsPage.js";

const ClientRoutes = ({
    user
                      }) => (
    <Routes>
        <Route index element={<ClientListPage />} />
        <Route path=':id' element={<ClientViewPage />} />
        <Route path=':id/edit' element={hasPermission(user, 'update-clients') ? <ClientEditPage /> : <ClientViewPage />}  />
        <Route path=':id/orders' element={hasPermission(user, 'get-clients') ? <ClientOrdersPage /> : <ClientViewPage />} />
        <Route path=':id/cars' element={hasPermission(user, 'get-clients') ? <ClientCarsPage /> : <ClientViewPage />} />
    </Routes>
);

export default ClientRoutes;