import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchCarModel, resetStateData, saveCarModel} from "../../../slices/carModelSlice.js";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import {fetchCarManufacturers} from "../../../slices/carManufacturerListSlice.js";
import CarModelForm from "./Components/CarModelForm.js";
import { getValidationSchema} from "../../../utils/validation.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import CarModelNav from "./Components/CarModelNav.js";

const CarModelPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: carModel, loading, errors: stateErrors, status} = useSelector((state) => state.carModel)
    const {data: carManufacturers, loading: carManufacturersLoading} = useSelector((state) => state.carManufacturerList)
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const carModelSchema = getValidationSchema({
        name: ['required', ['max', 50], ['min', 2]],
        synonyms: [['max', 255]],
        carManufacturer: ['required', 'object']
    })

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCarModel({id: id}));
        }
        dispatch(fetchCarManufacturers({}));
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/car-models', {state: {message: 'Успешно запазен модел'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, carModelSchema, saveCarModel, dispatch, carModel);
    };

    const showForm = () => {
        return !loading && !carManufacturersLoading;
    }

    const getTitle = () => {
        return idIsInteger ? `Модел № ${id}` : 'Нов Модел';
    }

    return (
        <SingleEditLayout
            title={getTitle()}
            showForm={() => showForm()}
            onSubmit={handleSubmit}
            navComponent={CarModelNav}
            navProps={{id: id, activeKey: 'edit'}}
        >
            <CarModelForm
                carModel={carModel}
                onAttributeChange={handleAttributeChange}
                carManufacturers={carManufacturers}
                errors={errors}
            />
        </SingleEditLayout>
    );
};

export default CarModelPage;
