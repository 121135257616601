import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from "react-router-dom";
import {changeAttribute, fetchCar, resetStateData, saveCar} from "../../../slices/carSlice.js";
import {processIdInformation, processSubmit} from "../../../utils/functions.js";
import CarForm from "./components/CarForm.js";
import SingleEditLayout from "../../../components/Layouts/SingleEditLayout.js";
import CarNav from "./components/CarNav.js";
import {getCarSchema} from "../../../utils/schemas.js";

const CarEditPage = () => {
    const {id} = useParams();
    const {idIsInteger, isValidId} = processIdInformation(id);

    const dispatch = useDispatch();
    const {data: car, loading, errors: stateErrors, status} = useSelector((state) => state.car)

    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    const carSchema = getCarSchema();

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCar({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    useEffect(() => {
        if ((status === 'saved' && !stateErrors.length) || !isValidId) {
            dispatch(resetStateData());
            navigate('/cars', {state: {message: 'Успешно запазена кола'}});
        }
    }, [navigate, stateErrors, status, dispatch, isValidId]);

    const handleAttributeChange = (attribute, value) => {
        dispatch(changeAttribute({attribute, value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await processSubmit(setErrors, carSchema, saveCar, dispatch, car);
    };

    const showForm = () => {
        return !loading;
    }

    const getTitle = () => {
        return idIsInteger ? `Кола № ${id}` : 'Нова Кола';
    }

    return (
        <SingleEditLayout
            title={getTitle()}
            showForm={() => showForm()}
            onSubmit={handleSubmit}
            navComponent={CarNav}
            navProps={{id: id, activeKey: 'edit'}}
            stateErrors={stateErrors}
        >
            <CarForm
                car={car}
                onSubmit={handleSubmit}
                onAttributeChange={handleAttributeChange}
                errors={errors}
            />
        </SingleEditLayout>
    );
};

export default CarEditPage;
