import React, {useState, useEffect } from 'react';
import { Navbar, Offcanvas } from 'react-bootstrap';
import MenuComponent from "../components/MenuComponent.js";
import {useLocation} from "react-router-dom";

const Sidebar = (
    props
) => {
    const location = useLocation(); // Hook to track location changes
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const user = props.user;

    useEffect(() => {
        setShowOffcanvas(false);
    }, [location]); // This runs every time the location changes

    const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

    useEffect(() => {
        if (showOffcanvas) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Cleanup function to remove the class when the component unmounts or the Offcanvas closes
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [showOffcanvas]);

    return (
        <>
            <Navbar bg="light" expand="lg" className="mb-3 position-fixed top-0 p-2">
                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggleOffcanvas}/>
            </Navbar>

            <Offcanvas show={showOffcanvas} onHide={toggleOffcanvas} placement="start" className={'vh-100'}>
                <Offcanvas.Header closeButton className={'col-11'}>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                   <MenuComponent user={user} />
                </Offcanvas.Body>
            </Offcanvas>

            <div className="sidebar d-none d-lg-block bg-light">
                <MenuComponent user={user} />
            </div>
        </>
    );
};

export default Sidebar;
