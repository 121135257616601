import React from 'react';
import { Form } from 'react-bootstrap';

function FormInput(
    {
        label,
        name,
        value,
        onChange,
        helpText = '',
        errors = [],
        type = 'text',
        as = ''
    }) {

    const handleChange = (e) => {
        onChange(e.target.name, e.target.value)
    }

    return (
        <Form.Group className={'align-items-center mb-2'}>
            {label && (
                <Form.Label className={'me-2'}>{label}</Form.Label>
            )}
            <Form.Control
                name={name}
                value={value === null ? '' : value}
                onChange={handleChange}
                type={type}
                isInvalid={!!errors[name]}
                as={as ? as : 'input'}
                rows={as === 'textarea' ? 5 : undefined}
            />
            {helpText && (
                <Form.Text className="text-muted">
                    {helpText}
                </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default FormInput;
