import {Button, Card, Table} from "react-bootstrap";
import {formatDate} from "../../../../utils/dateFunctions.js";
import {Link} from "react-router-dom";
import React from "react";
import LoadingComponent from "../../../../components/LoadingComponent.js";

const CarsTable = ({
    cars,
    showTable
                   }) => {

    return (
        <Card>
            {showTable() ? (
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>№</th>
                        <th>Клиент</th>
                        <th>Рег. номер</th>
                        <th>Марка/Модел</th>
                        <th>ВИН</th>
                        <th>Първа регистратия</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {cars.map((car) => (
                        <tr key={car.id}>
                            <td>{car.id}</td>
                            <td>{car.client.name}</td>
                            <td>{car.registrationNumber}</td>
                            <td>{car.carModel.modelManufacturerName}</td>
                            <td>{car.vinNumber}</td>
                            <td>{formatDate(car.firstRegisteredAt)}</td>
                            <td>
                                <Button variant="link">
                                    <Link to={`/cars/${car.id}`}>Преглед</Link>
                                </Button>
                                <Button variant="link">
                                    <Link to={`/cars/${car.id}/edit`}>Редактирай</Link>
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </Table>
            ) : (
                <LoadingComponent/>
            )}
        </Card>
    )
}

export default CarsTable;