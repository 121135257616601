import {Card} from "react-bootstrap";
import ErrorAlertComponent from "../ErrorAlertComponent.js";
import React from "react";
import LoadingComponent from "../LoadingComponent.js";
import MultipleFileUploadComponent from "../MultipleFileUploadComponent.js";
import FileListComponent from "../FileListComponent.js";

const SingleFilesLayout = ({
    title,
    showPage,
    stateErrors,
    navComponent : NavComponent,
    navProps,
    item,
    fetchItem,
    dispatch,
    itemsPath
                    }) => {
    const id = item.id;

    return (
        <div>
            <h2>{title}</h2>
            {NavComponent && <NavComponent {...navProps} />}
            <Card>
                {showPage ? (
                    <>
                        <Card.Header>
                            <MultipleFileUploadComponent
                                fetchItem={fetchItem}
                                id={id}
                                itemsPath={itemsPath}
                            />
                        </Card.Header>
                        <Card.Body>
                            <ErrorAlertComponent error={stateErrors}/>
                            <FileListComponent files={item.files} itemsPath={itemsPath} id={id}
                                               onFileDelete={() => dispatch(fetchItem({id: id}))}/>
                        </Card.Body>
                    </>
                ) : (
                    <LoadingComponent/>
                )}
            </Card>
        </div>
    )
};

export default SingleFilesLayout;