const Header = () => {

    return (
        <>
            <div className="header bg-light">
                <h1>Сервиз app</h1>
            </div>
        </>
    )
}

export default Header;