import {Card} from "react-bootstrap";
import ErrorAlertComponent from "../ErrorAlertComponent.js";
import Button from "react-bootstrap/Button";
import React from "react";
import LoadingComponent from "../LoadingComponent.js";

const SingleEditLayout = ({
    title,
    showForm,
    onSubmit,
    stateErrors,
    children,
    navComponent : NavComponent,
    navProps
                    }) => {

    return (
        <div>
            <h2>{title}</h2>
            {NavComponent && <NavComponent {...navProps} />}
            <Card>
                {showForm() ? (
                    <>
                        <Card.Body>
                            <ErrorAlertComponent error={stateErrors}/>
                            {children}
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="success" type="submit" onClick={onSubmit}>
                                Запази
                            </Button>
                        </Card.Footer>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </Card>

        </div>
    )
}

export default SingleEditLayout;