import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchCarModel,
    resetStateData,
} from "../../../slices/carModelSlice.js";
import {useParams} from "react-router-dom";
import Row from "react-bootstrap/Row";
import CarModelNav from "./Components/CarModelNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";
import {processIdInformation} from "../../../utils/functions.js";

const CarModelViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: carModel, loading} = useSelector((state) => state.carModel);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCarModel({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const getTitle = () => {
        return `Модел № ${id}`;
    }

    const showPage = () => {
        return !loading;
    }

    return (
        <SingleViewLayout
            title={getTitle()}
            navComponent={CarModelNav}
            navProps={{id:id, activeKey:'view'}}
            showPage={() => showPage()}
        >
            <Row>
                <div>
                    <div><b>Име: </b>{carModel.name}</div>
                </div>
            </Row>
        </SingleViewLayout>
    );
};

export default CarModelViewPage;
