import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchOrder,
    resetStateData,
} from "../../../slices/orderSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import OrderNav from "./components/OrderNav.js";
import SingleFilesLayout from "../../../components/Layouts/SingleFilesLayout.js";

const OrderFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: order, loading, errors: stateErrors} = useSelector((state) => state.order);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchOrder({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    return (
        <SingleFilesLayout
            title={`Поръчка № ${id}`}
            showPage={!loading}
            stateErrors={stateErrors}
            navComponent={OrderNav}
            navProps={{id: id, activeKey: 'files'}}
            item = {order}
            fetchItem={fetchOrder}
            dispatch={dispatch}
            itemsPath={'orders'}
        />
    );
};

export default OrderFilesPage;
