import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchClient,
    resetStateData,
} from "../../../slices/clientSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import Row from "react-bootstrap/Row";
import ClientNav from "./Components/ClientNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";

const ClientViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: client, loading} = useSelector((state) => state.client);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchClient({id: id}));
        }
    }, [id, dispatch, idIsInteger]);


    return (
        <SingleViewLayout
            title={idIsInteger ? `Клиент № ${id}` : 'Нов клиент'}
            navComponent={ClientNav}
            navProps={{id: id, activeKey: 'view'}}
            showPage={() => !loading}
            >
            <Row>
                <div>
                    <div><b>Име: </b>{client.name}</div>
                </div>
            </Row>
        </SingleViewLayout>
    );
};

export default ClientViewPage;
