import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {hasPermission} from "../utils/permissions.js";
import {Accordion, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";

const MenuComponent = ({ user}) => {
    const [activeKey, setActiveKey] = useState(null);
    const location = useLocation();
    const naviate = useNavigate();

    const handleToggleActive = (active) => {
        toggleActive(active)
    }

    const handleLogout = () => {
        naviate('/logout');
    };

    useEffect(() => {
        setActiveKey(null);
        // Open the accordion if the current path is /users or /roles
        if (location.pathname === '/users' || location.pathname === '/roles') {
            setActiveKey('users');
        }
        if (location.pathname.startsWith('/car')) {
            setActiveKey('cars');
        }
    }, [location.pathname]);

    const toggleActive = (key) => setActiveKey(activeKey === key ? null : key);

    return (
        <>
            <div className={'menu-items'}>
                <div className={'menu-top-item'}>
                    <NavLink to="/home">Начало</NavLink>
                </div>
                {hasPermission(user, 'get-orders') &&
                    <div className={'menu-top-item'}>
                        <NavLink to="/orders">Поръчки</NavLink>
                    </div>}
                {hasPermission(user, 'get-clients') &&
                    <div className={'menu-top-item'}>
                        <NavLink to="/clients">Клиенти</NavLink>
                    </div>}
                {hasPermission(user, 'get-articles') &&
                    <div className={'menu-top-item'}>
                        <NavLink to="/articles">Артикули</NavLink>
                    </div>}
                {hasPermission(user, 'get-cars') &&
                    <Accordion activeKey={activeKey} className={'menu-top-item'}>
                        <div
                            className={activeKey === 'cars' ? 'active' : ''}
                            onClick={() => handleToggleActive('cars')}
                            style={{cursor: 'pointer'}}
                        >
                            Коли
                        </div>
                        <Accordion.Collapse eventKey="cars">
                            <div>
                                <div>
                                    <NavLink to="/cars">Коли</NavLink>
                                </div>

                                <div>
                                    <NavLink to="/car-models">Модели</NavLink>
                                </div>
                                <div>
                                    <NavLink to="/car-manufacturers">Производители</NavLink>
                                </div>

                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                }

                {hasPermission(user, 'get-users') && (
                    <Accordion activeKey={activeKey} className={'menu-top-item'}>
                        <div
                            className={activeKey === 'users' ? 'active' : ''}
                            onClick={() => handleToggleActive('users')}
                            style={{cursor: 'pointer'}}
                        >
                            Потребители
                        </div>
                        <Accordion.Collapse eventKey="users">
                            <div>
                                <div>
                                    <NavLink to="/users">Потребители</NavLink>
                                </div>
                                <div>
                                    <NavLink to="/roles">Роли</NavLink>
                                </div>
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                )}
            </div>
            <div className="position-absolute bottom-0 left-0 pb-2">
                <Button variant="danger"
                        onClick={handleLogout}>Logout</Button>
            </div>
        </>
    )
}

export default MenuComponent;