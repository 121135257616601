import { createSlice } from '@reduxjs/toolkit';
import {fetchItems} from "../utils/sliceFunctions.js";
import {getCommonExtraReducersList} from "../utils/commonExtraReducers.js";

const initialState = {
    data: [],
    pagination: {
        currentPage: 1,
        lastPage: 1,
        totalItems: 0
    },
    loading: false,
    error: null
}
export const fetchOrderStatuses = fetchItems('orderStatuses/fetchOrderStatuses', '/order-statuses');

const orderStatusListSlice = createSlice({
    name: 'orderStatuses',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        getCommonExtraReducersList(builder, fetchOrderStatuses)
    },
});

export default orderStatusListSlice.reducer;