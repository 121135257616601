import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React from "react";
import SearchAutoCompleteComponent from "../../../../components/SearchAutoCompleteComponent.js";
import {fetchClients} from "../../../../slices/clientListSlice.js";
import {fetchCarModels} from "../../../../slices/carModelListSlice.js";
import DateInput from "../../../../components/Elements/DateInput.js";
import {Link} from "react-router-dom";

const CarForm = (
    {
        car,
        onAttributeChange,
        errors,
        forceClient
    }) => {

    return (
        <Form>

            <Form.Group>
                <Row>
                    <Col md={4}>
                        <SearchAutoCompleteComponent
                            onItemChange={onAttributeChange}
                            item={forceClient ? forceClient : car.client}
                            name={'client'}
                            label={'Клиент'}
                            fetchItems={fetchClients}
                            reducer={'clientList'}
                            errors={errors}
                            disabled={!!forceClient}
                        />
                    </Col>
                    <Col md={4}>
                        <FormInput label="Рег. номер"
                                   name="registrationNumber"
                                   value={car.registrationNumber}
                                   onChange={onAttributeChange}
                                   errors={errors}
                                   helpText={'Въведи латиница и числа (напр. СС1010АА)'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <SearchAutoCompleteComponent
                            onItemChange={onAttributeChange}
                            item={car.carModel}
                            name={'carModel'}
                            label={'Марка/Модел'}
                            fetchItems={fetchCarModels}
                            reducer={'carModelList'}
                            errors={errors}
                            itemLabel={'modelManufacturerName'}
                        />
                    </Col>
                    <Col md={1} className="d-flex flex-column align-items-start">
                        <div className={'mt-auto'}>
                            <Link className={'small'} to={'/car-models/create/edit'} target={'_blank'}>Добави
                                модел</Link>
                            <br/>
                            <Link className={'small'} to={'/car-manufacturers/create/edit'} target={'_blank'}>Добави
                                производител</Link>
                        </div>
                    </Col>
                    <Col md={4}>
                        <FormInput label="ВИН"
                                   name="vinNumber"
                                   value={car.vinNumber}
                                   onChange={onAttributeChange}
                                   errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <DateInput label={'Първа регистрация'} dateValue={car.firstRegisteredAt}
                                   name={'firstRegisteredAt'}
                                   onDateValueChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormInput
                            name={'paintCode'}
                            value={car.paintCode ? car.paintCode : ''}
                            onChange={onAttributeChange}
                            label={'Код боя'}
                            errors={errors}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormInput as={'textarea'} label="Допълнителна информация" name="additionalInformation"
                                   value={car.additionalInformation}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
};

export default CarForm;