import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchCar,
    resetStateData,
} from "../../../slices/carSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation} from "../../../utils/functions.js";
import SingleFilesLayout from "../../../components/Layouts/SingleFilesLayout.js";
import CarNav from "./components/CarNav.js";

const CarFilesPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: car, loading, errors: stateErrors} = useSelector((state) => state.car);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCar({id: id}));
        }
    }, [id, dispatch, idIsInteger]);


    return (
        <SingleFilesLayout
            title={`Кола № ${id}`}
            showPage={!loading}
            stateErrors={stateErrors}
            navComponent={CarNav}
            navProps={{id: id, activeKey: 'files'}}
            item = {car}
            fetchItem={fetchCar}
            dispatch={dispatch}
            itemsPath={'cars'}
        />
    );
};



export default CarFilesPage;
