import {Nav} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

const CarNav = ({
                                 id,
                                 activeKey
                             }) => {

    return (
        <Nav variant="tabs" activeKey={activeKey} defaultActiveKey="show">
            {id !== 'create' && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'view'} to={`/cars/${id}`}>Преглед</Nav.Link>
                </Nav.Item>
            )}
            <Nav.Item>
                <Nav.Link as={Link} eventKey={'edit'} to={`/cars/${id}/edit`}>Редактирай</Nav.Link>
            </Nav.Item>
            {id !== 'create' && (
                <Nav.Item>
                    <Nav.Link as={Link} eventKey={'files'} to={`/cars/${id}/files`}>Файлове</Nav.Link>
                </Nav.Item>
            )}
        </Nav>
    )
}

export default CarNav;