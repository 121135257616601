import React from 'react';

const Home = (
) => {
    return (
        <div>
            <h2>Home Page</h2>
        </div>
    );
};

export default Home;