import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchOrder,
    resetStateData,
} from "../../../slices/orderSlice.js";
import {useParams} from "react-router-dom";
import {processIdInformation, toFloatBgn} from "../../../utils/functions.js";
import OrderNav from "./components/OrderNav.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {formatDateToBeautifulString} from "../../../utils/dateFunctions.js";
import OrderLinesTableComponent from "./components/OrderLinesTableComponent.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";

const OrderViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: order, loading} = useSelector((state) => state.order);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchOrder({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    return (
        <SingleViewLayout
            title={idIsInteger ? `Поръчка № ${id}` : 'Нова поръчка'}
            showPage={() => !loading && order.id}
            navComponent={OrderNav}
            navProps={{id:id, activeKey: 'view'}}
            >
            <Row>
                <Col md={6}>
                    <div className={'p-2 border-dark border-1 border d-inline-block'}>
                        <div><b>Номер:</b> {order.id}</div>
                        <div><b>Дата:</b> {formatDateToBeautifulString(order.createdAt)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12} className={'mt-3'}>
                    <div className={'p-2 border-dark border-1 border d-inline-block'}>
                        <div><b>Получател:</b> {order.client?.name}</div>
                        <div><b>ЕИГ/ЕНГ:</b> {order.client?.identificationId}</div>
                        <div><b>ДДС:</b> {order.client?.vatNumber}</div>
                        <div><b>Град:</b> {order.client?.city}</div>
                        <div><b>Адрес:</b> {order.client?.address}</div>
                        <div><b>МОЛ:</b> {order.client?.molField}</div>
                        <div><b>Телефон:</b> {order.client?.phoneNumber}</div>
                        <div><b>Email:</b> {order.client?.email}</div>
                    </div>
                </Col>
            </Row>
            <Row className={'mt-3'}>
                <OrderLinesTableComponent lines={order.orderLines} showingActiveLines={true} viewOnly={true} />

                <div>
                    <div><b>Данъчна основа:</b> {toFloatBgn(order.totalWithoutVat)}</div>
                    <div><b>ДДС:</b> {toFloatBgn(order.vatOfTotal)}</div>
                    <div><b>Сума за плащане:</b> {toFloatBgn(order.total)}</div>
                </div>
            </Row>
        </SingleViewLayout>
    );
};

export default OrderViewPage;
