import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormInput from "../../../../components/Elements/FormInput.js";
import React from "react";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import {getVatFloatBgn} from "../../../../utils/functions.js";
import FormCheckbox from "../../../../components/Elements/FormCheckbox.js";
import {Card} from "react-bootstrap";

const ArticleForm = ({
    article,
    onAttributeChange,
    errors,
    quantityTypes,
    articleTypes,
    onSettingsChange
                     }) => {

    return (
        <Form>
            <Form.Group>
                <Row>
                    <Col md={3}>
                        <SelectInput
                            label={'Вид артикул'}
                            value={article.articleType ? article.articleType.id : ''}
                            name={'articleType'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={articleTypes}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormInput label='Име' name='name' value={article.name}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormInput label='Код' name='skuNumber' value={article.skuNumber}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                    <Col md={3}>
                        <FormInput label='Код прозиводител' name='oemNumber' value={article.oemNumber}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <FormCheckbox label='Нов (не е втора ръка)' name='isNew' isChecked={article.isNew}
                                  onCheckboxChange={onAttributeChange} errors={errors}/>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormInput label='Производител' name='manufacturer' value={article.manufacturer}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>

                    <Col md={3}>
                        <FormInput label='Доставчик' name='supplier' value={article.supplier}
                                   onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <SelectInput
                            label={'Мерна единица'}
                            value={article.quantityType ? article.quantityType.id : ''}
                            name={'quantityType'}
                            errors={errors}
                            onChange={onAttributeChange}
                            selectOptions={quantityTypes}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormNumericInput label='Продажна цена' name='sellingPrice' value={article.sellingPrice}
                                          onChange={onAttributeChange} errors={errors} step={0.1}/>
                        <span>ДДС: {getVatFloatBgn(article.sellingPrice)}</span>
                    </Col>
                    <Col md={3}>
                        <FormNumericInput label='Покупна цена' name='costPrice' value={article.costPrice}
                                          onChange={onAttributeChange} errors={errors} step={0.1}/>
                        <span>ДДС: {getVatFloatBgn(article.costPrice)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormNumericInput label='Наличност' name='stock' value={article.stock}
                                          onChange={onAttributeChange} errors={errors}/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <Card className={'mt-2'}>
                            <Card.Body>
                                <Row>
                                    <Col sm={4}>
                                        <FormCheckbox
                                            label={'Задължителна бележка в поръчка'}
                                            name={'mandatoryLineNote'}
                                            onCheckboxChange={onSettingsChange}
                                            isChecked={article.settings?.mandatoryLineNote ? article.settings.mandatoryLineNote : false}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <FormCheckbox
                                            label={'Задължително добавя артикул продукт като подлияния'}
                                            name={'mandatoryArticleProductLine'}
                                            onCheckboxChange={onSettingsChange}
                                            isChecked={article.settings?.mandatoryArticleProductLine ? article.settings.mandatoryArticleProductLine : false}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <FormInput errors={errors} onChange={onAttributeChange} name={'additionalInformation'}
                                   value={article.additionalInformation}
                                   label={'Бележка'} as={'textarea'}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
}

export default ArticleForm