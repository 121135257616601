import {Card} from "react-bootstrap";
import React from "react";
import LoadingComponent from "../LoadingComponent.js";

const SingleViewLayout = ({
    title,
    children,
    showPage,
    navComponent : NavComponent,
    navProps
                    }) => {
    return (
        <div>
            <h2>{title}</h2>
            {NavComponent && <NavComponent {...navProps} />}
            <Card>
                {showPage() ? (
                    <Card.Body>
                        {children}
                    </Card.Body>
                ) : (
                    <LoadingComponent />
                )}

            </Card>
        </div>
    )
};

export default SingleViewLayout;