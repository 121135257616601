import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams} from "react-router-dom";
import {fetchArticleOrders} from "../../../slices/orderListSlice.js";
import OrdersTable from "../../Orders/List/components/OrdersTable.js";
import {fetchArticle} from "../../../slices/articleSlice.js";
import ButtonLink from "../../../components/Elements/ButtonLink.js";

const ArticleOrdersListPage = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const { data: orders, loading: ordersLoading } = useSelector((state) => state.orderList);
    const { data: article, loading: articleLoading } = useSelector((state) => state.article);

    useEffect(() => {
        dispatch(fetchArticleOrders({id: id}));
        dispatch(fetchArticle({id: id}));
    }, [dispatch, id]);

    return (
        <div>
            <ButtonLink to={'/articles'}>Артикули</ButtonLink>
            {(!ordersLoading && !articleLoading) && (
                <div>
                    <h2>Поръчки - {article.name}</h2>
                    <OrdersTable orders={orders} showTable={() => !ordersLoading}/>
                </div>
            )}
        </div>
    );
};

export default ArticleOrdersListPage;
