import Form from "react-bootstrap/Form";
import { toFloatBgn} from "../../../../utils/functions.js";
import FormNumericInput from "../../../../components/Elements/FormNumericInput.js";
import React, {useEffect, useState} from "react";
import {
    fetchCarDetailArticles,
    fetchPaintArticles,
    resetStateData
} from "../../../../slices/articleListSlice.js";
import {useDispatch, useSelector} from "react-redux";
import SelectInput from "../../../../components/Elements/SelectInput.js";
import FormInput from "../../../../components/Elements/FormInput.js";
import LoadingComponent from "../../../../components/LoadingComponent.js";
import {fetchDamageLevels} from "../../../../slices/damageLevelListSlice.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Table} from "react-bootstrap";
import {v4 as uuidv4} from "uuid";

const EditOrderLinePaintComponent = (
    {
        editOrderLine,
        onArticleChange,
        errors,
        onAttributeChange
    }) => {
    const dispatch = useDispatch();
    const {data: appSettings} = useSelector((state) => state.appSettings);
    const [carDetailArticles, setCarDetailArticles] = useState(null);
    const [damageLevels, setDamageLevels] = useState(null);

    useEffect(() => {
        dispatch(resetStateData());
        return () => {
            dispatch(resetStateData());
        };
    }, [])

    useEffect(() => {
        dispatch(fetchDamageLevels()).then((result) => {
            setDamageLevels(result.payload.data)
        });
        // First, fetch car details articles
        dispatch(fetchCarDetailArticles()).then((result) => {
            setCarDetailArticles(result.payload.data);
            if (editOrderLine.childLines === null) {
                // Once car details are fetched, fetch paint articles
                dispatch(fetchPaintArticles()).then((result) => {
                    const data = result.payload.data;
                    const filteredSettings = appSettings.filter(setting =>
                        /^paint_\d+_\d+_article_id$/.test(setting.key)
                    );

                    filteredSettings.sort((a, b) => {
                        const aMatch = a.key.match(/paint_(\d+)_/);
                        const bMatch = b.key.match(/paint_(\d+)_/);
                        const aIndex = parseInt(aMatch ? aMatch[1] : 0);
                        const bIndex = parseInt(bMatch ? bMatch[1] : 0);
                        return aIndex - bIndex;
                    });
                    const paintArticles = filteredSettings.map(setting => {
                        // Find matching paint article based on `value` (which is the `id` in paintArticles)
                        const matchingArticle = data.find(article => article.id === parseInt(setting.value));
                        return matchingArticle ? matchingArticle : null;
                    });

                    const childLines = paintArticles.map(paintArticle => ({
                        id: uuidv4(),
                        article: paintArticle,
                        price: 0, // Set default price, or populate as needed
                        discount: 0, // Set default discount
                        quantity: 0, // Set default quantity,
                        orderLineType: editOrderLine.orderLineType,
                        order: editOrderLine.order,
                        useStock: false,
                        childLines: [],
                        additionalInformation: null,
                        isActive: true,
                        totalPrice: 0
                    }));

                    onAttributeChange('childLines', childLines);
                    onAttributeChange('quantity', 1)
                    dispatch(resetStateData());
                });
            }
            dispatch(resetStateData());
        });
    }, []);

    const handleChildLineChange = (line, name, value) => {
        const childLinesCopy = editOrderLine.childLines.map(childLine => ({
            ...childLine
        }));
        const updatedChildLines = childLinesCopy.map(childLine => {
            if (childLine.id === line.id) {
                const updatedLine = {...childLine, [name]: value};
                updatedLine.totalPrice = calculateChildLineLineTotal(updatedLine);
                return updatedLine;
            }
            return childLine;
        });
        onAttributeChange('childLines', updatedChildLines);
        const overallTotal = updatedChildLines.reduce((sum, line) => sum + line.totalPrice, 0);
        onAttributeChange('totalPrice', overallTotal);
        onAttributeChange('price', overallTotal);
    };

    const calculateChildLineLineTotal = (line) => {
        return (line.quantity * line.price) * ((100 - line.discount) / 100);
    }

    return (
        <>
            {(damageLevels && carDetailArticles && editOrderLine.childLines) ?
                <Form.Group className="mb-3" controlId="formHorizontalEmail">
                    <Row>
                        <Col md={3}>
                            <FormInput
                                name={'paintCode'}
                                value={editOrderLine.paintCode ? editOrderLine.paintCode : ''}
                                onChange={onAttributeChange}
                                label={'Код боя'}
                                errors={errors}
                            />
                        </Col>
                        <Col md={6}>

                            <SelectInput
                                label={'Детайл'}
                                value={editOrderLine.article ? editOrderLine.article.id : ''}
                                name={'article'}
                                errors={errors}
                                onChange={onArticleChange}
                                selectOptions={carDetailArticles}
                            />
                        </Col>
                        <Col md={6}>
                            <SelectInput
                                label={'Степен на увреждане'}
                                value={editOrderLine.damageLevel ? editOrderLine.damageLevel.id : ''}
                                name={'damageLevel'}
                                errors={errors}
                                onChange={onAttributeChange}
                                selectOptions={damageLevels}
                            />
                        </Col>
                    </Row>
                    <Row className={'mt-2'}>
                        <Col md={12}>
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th className={'col-5'}></th>
                                    <th className={'col-2'}>Количество</th>
                                    <th className={'col-1'}>Мярка</th>
                                    <th className={'col-1'}>Цена</th>
                                    <th className={'col-1'}>Отстъпка</th>
                                    <th className={'col-2'}>Цена общо</th>
                                </tr>
                                </thead>
                                <tbody>
                                {editOrderLine.childLines.map((childLine) =>
                                    (
                                        <tr key={childLine.id}
                                            style={childLine.article.articleType.label === 'chamber' ? {
                                                border: '3px solid black',
                                                'padding': '10px'
                                            } : {}}>
                                            <td className={'col-5'}>{childLine.article.name}</td>
                                            <td className={'col-2'}>
                                                <FormNumericInput
                                                    name={'quantity'}
                                                    value={childLine.quantity}
                                                    onChange={(name, value) => {

                                                        handleChildLineChange(childLine, name, value)
                                                    }}
                                                />
                                            </td>
                                            <td className={'col-1'}>{childLine.article.quantityType.name}</td>

                                            <td className={'col-1'}>
                                                <FormNumericInput
                                                    name={'price'}
                                                    value={childLine.price}
                                                    onChange={(name, value) => {
                                                        handleChildLineChange(childLine, name, value)
                                                    }}
                                                />
                                            </td>
                                            <td className={'col-1'}>
                                                <FormNumericInput
                                                    name={'discount'}
                                                    value={childLine.discount}
                                                    onChange={(name, value) => {
                                                        handleChildLineChange(childLine, name, value)
                                                    }}
                                                />
                                            </td>
                                            <td className={'col-2'}>{toFloatBgn(childLine.totalPrice)}</td>

                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h5>Цена общо (с ДДС): {toFloatBgn(editOrderLine.totalPrice)}</h5>
                        </Col>
                    </Row>
                </Form.Group> :
                <LoadingComponent/>
            }
        </>
    )
};

export default EditOrderLinePaintComponent;