import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchCar,
    resetStateData,
} from "../../../slices/carSlice.js";
import {useParams} from "react-router-dom";
import { processIdInformation} from "../../../utils/functions.js";
import Row from "react-bootstrap/Row";
import CarNav from "./components/CarNav.js";
import SingleViewLayout from "../../../components/Layouts/SingleViewLayout.js";

const CarViewPage = () => {
    const {id} = useParams();
    const {idIsInteger} = processIdInformation(id);
    const dispatch = useDispatch();

    const {data: car, loading} = useSelector((state) => state.car);

    useEffect(() => {
        dispatch(resetStateData());
        if (idIsInteger) {
            dispatch(fetchCar({id: id}));
        }
    }, [id, dispatch, idIsInteger]);

    const showPage = () => {
        return !loading && car.id;
    }

    const getTitle = () => {
        return `Кола № ${id}`;
    }
    return (
        <SingleViewLayout
            title={getTitle()}
            navComponent={CarNav}
            navProps={{id:id, activeKey: 'view'}}
            showPage={() => showPage()}
            >
            <Row>
                <div>
                    <div><b>Рег.номер: </b>{car.registrationNumber}</div>
                    <div><b>Клиент: </b>{car.client?.name}</div>
                </div>
            </Row>
        </SingleViewLayout>
    );
};

export default CarViewPage;
